import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '@/components/SEO/Seo'

import Layout from '@/components/Layout'
import Hero from '@/components/Hero/Hero'
import InfoCard from '@/components/InfoCard/InfoCard'
import ReactMarkdown from 'react-markdown'
import ReactSVG from 'react-svg'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import scrollTo from 'gatsby-plugin-smoothscroll'

const HomePageTemplate = ({ data }) => {
  const pageData = data.markdownRemark.frontmatter
  const cardData = data.contact.edges[0].node.frontmatter

  return (
    <>
      <Hero
        data={cardData}
        main
        fluid={pageData.background.childImageSharp.fluid}
      />
      <section
        id='change'
        className='change items-center justify-center bg-white'>
        <div className='container  relative'>
          <div className='change-wrap '>
            <ReactMarkdown
              className='markdown-content'
              source={pageData.change.body}
            />
            <a href='#onlinebookings' className='btn btn__green'>
              Book a visit
            </a>
          </div>
          <Img
            className='change-img'
            style={{ position: 'absolute' }}
            alt='Shihair hairdresser interior'
            fluid={pageData.change.image.childImageSharp.fluid}></Img>
        </div>
      </section>
      <section
        id='services'
        className=' flex items-center justify-center bg-white lg:pt-40 pt-20 relative'>
        <BackgroundImage
          className='experience-bg'
          style={{ position: 'absolute' }}
          fluid={pageData.experience.background.childImageSharp.fluid}
        />
        <div className='container  relative'>
          <ReactMarkdown
            className='experience-content'
            source={pageData.experience.body}></ReactMarkdown>

          <div className='info-card__wrap'>
            <InfoCard source={pageData.experience.cuts} />
            <InfoCard source={pageData.experience.styling} />
            <InfoCard source={pageData.experience.misc} />
          </div>
          <p className='mt-8 block text-right text-grey-dark'>
            * depending on stylists experience
          </p>

          <div className='btn__container'>
            <a href='#onlinebookings' className='btn btn__green btn--l mr-2'>
              Book a visit
            </a>
            <button
              onClick={() => {
                scrollTo('#footer')
              }}
              className='btn btn__white btn--l ml-2'>
              Contact us
            </button>
          </div>
        </div>
      </section>
      <section id='mobileapp' className='pt-24 md:pt-64'>
        <div className='container'>
          <div className='app '>
            <div className='app__left'>
              <ReactSVG className='app__image ' src='/img/svg/app.svg' />
            </div>
            <div className='app__right'>
              <ReactMarkdown className='app__text' source={pageData.app.body} />
              <div className='flex justify-center md:justify-start mt-8'>
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href='https://play.google.com/store/apps/details?id=com.phorest.shihairdressing'>
                  <ReactSVG src='/img/svg/gplay.svg' />
                  <div className='sr-only'>
                    Download ShiHair app on Google Play Store
                  </div>
                </a>
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href='https://apps.apple.com/us/app/shi-hairdressing/id1497870046'>
                  <ReactSVG src='/img/svg/appstore.svg' />
                  <div className='sr-only'>
                    Download ShiHair app on App Store
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id='tour'
        className=' flex items-center justify-center bg-white pb-20 pt-24'>
        <div className='container '>
          <ReactMarkdown
            className='text-black text-center mb-6'
            source={pageData.tour.body}
          />
          <iframe
            className='tour-map'
            allowFullScreen=''
            frameBorder='0'
            title='Shi Hair Virtual Tour'
            height='563'
            width='100%'
            loading='lazy'
            src='https://www.google.com/maps/embed?pb=!4v1612259543816!6m8!1m7!1sCAoSLEFGMVFpcE1lUlJqMEhXQ2pXSVZOVk0wZVdjc19oTGlEWlR4dzZhWHE5ODl6!2m2!1d-35.316607534158!2d149.14144821529!3f14.737507749698418!4f-1.5562234168961737!5f0.7820865974627469'></iframe>
        </div>
      </section>
      <section
        id='rewards'
        className=' flex items-center justify-center bg-white pb-20 text-black'>
        <div className='container '>
          <ReactMarkdown
            className='markdown-content text-center lg:text-left'
            source={pageData.rewards.body}
          />
          <div className='flex justify-center lg:justify-start '>
            <a
              href={pageData.rewards.socials.facebook}
              rel='noopener noreferrer'
              target='_blank'
              className='rewards-social-item bg-grey-light'>
              <ReactSVG src='/img/svg/facebook_blue.svg'></ReactSVG>
              <div className='sr-only'>Shihair's Facebook</div>
            </a>
            <a
              href={pageData.rewards.socials.twitter}
              rel='noopener noreferrer'
              target='_blank'
              className='rewards-social-item bg-grey-light'>
              <ReactSVG src='/img/svg/twitter_colored.svg'></ReactSVG>
              <div className='sr-only'>Shihair's Twitter</div>
            </a>
            <a
              href={pageData.rewards.socials.instagram}
              rel='noopener noreferrer'
              target='_blank'
              className='rewards-social-item bg-grey-light'>
              <ReactSVG src='/img/svg/instagram_colored.svg'></ReactSVG>
              <div className='sr-only'>Shihair's Instagram</div>
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

const HomePage = ({ data }) => {
  return (
    <Layout hideNav={true}>
      <SEO data={data.markdownRemark.frontmatter.seo} />
      <HomePageTemplate data={data} />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query HomePageTemplate {
    markdownRemark(frontmatter: { template: { eq: "index" } }) {
      frontmatter {
        title
        background {
          childImageSharp {
            fluid(quality: 45, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cards {
          adress
          booking
        }
        rewards {
          body
          socials {
            instagram
            facebook
            twitter
          }
        }
        tour {
          body
        }
        change {
          body
          image {
            childImageSharp {
              fluid(quality: 45, maxWidth: 617) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        app {
          body
        }
        experience {
          body
          background {
            childImageSharp {
              fluid(quality: 45, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          cuts
          styling
          misc
        }
        ...SEO
      }
    }
    contact: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "contact" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            phone
            adress
            email
            booking
          }
        }
      }
    }
  }
`

export default HomePage

// background {
//   childImageSharp {
//     fluid(quality: 50, maxWidth: 2000) {
//       ...GatsbyImageSharpFluid
//     }
//   }
// }
